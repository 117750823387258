<template>
  <div class="FormWrap">
    <el-table :data="tableData" style="width: 100%; margin-top: 20px" border>
      <el-table-column label="操作" width="180" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="beginSet(scope.row)">期初设置</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="code" label="账户编号" width="180" align="center">
      </el-table-column>
      <el-table-column prop="name" label="账户名称" width="180" align="center">
      </el-table-column>
      <el-table-column prop="initialPayment" label="期初余额" width="180" align="center">
      </el-table-column>
      <el-table-column prop="remark" label="备注"> </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      @size-change="changePageSize"
      @current-change="changeCurrentPage"
      :current-page="pageNum"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-dialog title="期初设置" :visible.sync="dialogFormVisible" width="600px">
      <div class="F">
        <div style="width: 88px; text-align: right; margin: 0 14px 20px 0">
          名称:
        </div>
        <div>{{form.name}}</div>
      </div>
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="期初余额:" label-width="100px" prop="initialPayment">
          <el-input
            v-model="form.initialPayment"
            autocomplete="off"
            style="width: 450px"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save"
          >保 存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getAut,setAut} from '@/api/system'
export default {
  data() {
    return {
      param:"",
      pageNum: 1,
      pageSize: 20,
      total: 0,
      dialogFormVisible: false,
      form: {
        id:"",
        name: "",
        remark: "",
        initialPayment: "",
      },
      tableData: [
       
      ],
      rules: {
        initialPayment: [
          { required: true, message: "期初应收款不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getAut();
  },
  methods: {
    //保存
    save(){
      this.$refs['form'].validate(async (valid)=>{
        if(valid){
          let params = {
            id:this.form.id,
            initialPayment:this.form.initialPayment,
            remark:this.form.remark
          }
          let res = await setAut(params);
          if(res.code == 1){
            this.$message({
              type:"success",
              message:res.msg
            });
            this.dialogFormVisible = false;
          }else{
            this.$message({
              type:"warning",
              message:res.msg
            })
          }
        }
      })
    },
    //获取列表
    async getAut(){
      let params = {
        pageNum:this.pageNum,
        pageSize:this.pageSize,
        param:this.param
      }
      let res = await getAut(params);
      if(res.code == 1){
        this.tableData = res.data.records
        this.total = res.data.total;
      }
    },
    changePageSize(val) {
      this.pageSize = val;
      this.pageNum = 1;
      //   this.getData();
    },
    changeCurrentPage(val) {
      this.pageNum = val;
      //   this.getData();
    },
    beginSet(row) {
      this.form = {...row};
      this.dialogFormVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.F {
  display: flex;
  justify-content: start;
}
.pagination {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
</style>